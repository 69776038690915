




















import { Vue, Component, Watch } from 'vue-property-decorator'
import { Textbox, Card, PostButton, Validation, Functions, Form } from '../../lib/mavejs'
import { url } from '@/functions'
import Token from '@/classes/Token'

@Component({
  components: { Textbox, Card, PostButton, Validation },
  methods: { url: url },
  mixins: [Functions, Form]
})
export default class Login extends Vue {
  response = {
    data: {
      status: 500,
      token: '',
      user: {
        name: 'Guest',
        email: 'guest@gadmaker.mave.work',
        id: 0
      }
    }
  }

  model = {
    email: 'name@mave.media',
    password: ''
  }

  @Watch('response', { deep: true })
  handleResponseToken () {
    if (this.response.data.status === 200) {
      Token.setToken(this.response.data.token)
      Token.prepareToken()
      localStorage.setItem('user', JSON.stringify(this.response.data.user))
      this.$router.push('/')
    }
    if (this.response.data.status === 401) {
      window.alert('wrong E-mail and Password combination')
    }
  }
}

